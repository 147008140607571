import React from 'react';
import './App.sass';
import Landing from "./pages/landing/landing";

function App() {

    return (
        <Landing/>
    );
}

export default App;
