import React from "react";
import "./landing.sass";

const KUTE = require("kute.js");
require("kute.js/kute-svg");

class Landing extends React.Component {
    public animationOptions = {
        repeat: Infinity,
        duration: 20000,
        yoyo: true,
        morphPrecision: 4,
        morphIndex: 20
    };

    public blobs = [
        "brownBlob",
        "creamBlob",
        "greyBlob"
    ];

    componentDidMount(): void {
        this.startAbstractShapeAnimation();
    }

    wiggle(blobID: string) {
        KUTE.to(`#${blobID}`,
            { path: this.wigglePathGenerator(blobID), svgTransform: { rotate: this.getRandomInt(150, 360) } },
            this.animationOptions)
            .start()
    }

    wigglePathGenerator(blobID: string): string {
        const pathData = document?.getElementById(blobID)?.getAttribute("d")?.split(' ');

        const newPathData = pathData?.map((item, index) => {
            const offsetValue = this.getRandomInt(5, 10);
            const controlValue = 10;

            // Find the occurrence of letters in the path definition
            const indexOfLetter = item.search('[a-zA-Z]');
            let newItemValue;
            if (indexOfLetter === 0) {
                newItemValue = `${item[indexOfLetter]}${parseFloat(item.slice(indexOfLetter + 1)) + controlValue / offsetValue}`;
            } else if (indexOfLetter + 1 === item.length) {
                newItemValue = `${parseFloat(item.slice(0, indexOfLetter)) + controlValue / offsetValue}${item[indexOfLetter]}`;
            } else if (indexOfLetter > 0 && indexOfLetter < item.length) {
                const values = item.split(item[indexOfLetter]);
                newItemValue = `${parseFloat(values[0]) + controlValue / offsetValue}${item[indexOfLetter]}${parseFloat(values[1]) + controlValue / offsetValue}`
            } else {
                newItemValue = `${parseFloat(item) + controlValue / offsetValue}`;
            }
            return newItemValue;
        });

        if (newPathData) {
            return newPathData.join(' ');
        }

        return '';
    }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    startAbstractShapeAnimation() {
        this.blobs.forEach(blobID => {
            this.wiggle(blobID);
        })
    }

    render() {
        return (
            <div className="main fill-view-port">
                <svg overflow="visible" className="blobs">
                    <svg overflow="visible" x="15%" y="-24%">
                        <path id="greyBlob"
                            d="M289.552 0.926581C351.051 2.89011 396.202 55.1417 443.155 94.9081C486.405 131.538 535.818 166.815 550.162 221.648C564.18 275.237 536.272 328.264 517.864 380.508C499.969 431.298 486.647 486.138 444.962 520.229C402.017 555.351 344.953 564.304 289.552 567.23C231.387 570.302 171.95 566.818 121.737 537.301C68.2243 505.844 18.3193 459.531 3.70957 399.201C-10.5314 340.393 24.3453 283.645 46.6954 227.416C67.4488 175.204 87.5636 123.187 128.653 84.8668C174.002 42.5736 227.573 -1.05227 289.552 0.926581Z"
                            fill="#EAE2CF" />
                    </svg>

                    <svg overflow="visible" x="30%" y="-20%">
                        <path id="creamBlob"
                            d="M259.758 1.00856C314.899 2.76908 355.382 49.6183 397.48 85.2732C436.259 118.116 480.563 149.746 493.424 198.909C505.993 246.957 480.97 294.502 464.465 341.345C448.421 386.883 436.476 436.053 399.101 466.62C360.596 498.11 309.432 506.137 259.758 508.761C207.607 511.515 154.316 508.392 109.295 481.927C61.3144 453.722 16.5691 412.197 3.46992 358.104C-9.29869 305.377 21.9721 254.496 42.0114 204.081C60.6191 157.268 78.6542 110.628 115.495 76.27C156.156 38.3497 204.188 -0.765698 259.758 1.00856Z"
                            fill="#F2F2F2" />
                    </svg>

                    <svg overflow="visible" x="45%" y="0%">
                        <path id="brownBlob"
                            d="M217.426 0.826294C263.474 2.29652 297.282 41.4206 332.438 71.1963C364.822 98.6236 401.821 125.038 412.561 166.094C423.058 206.22 402.161 245.924 388.378 285.043C374.979 323.073 365.004 364.135 333.792 389.661C301.636 415.959 258.908 422.662 217.426 424.853C173.874 427.154 129.37 424.545 91.7727 402.444C51.704 378.89 14.3369 344.213 3.39773 299.039C-7.26541 255.006 18.849 212.515 35.5839 170.413C51.1233 131.319 66.1846 92.3704 96.9508 63.6776C130.907 32.0101 171.019 -0.655396 217.426 0.826294Z"
                            fill="#A8DADC" />
                    </svg>
                </svg>
                <div className="about-me">
                    <h1 className="title">Hello!</h1>
                    <p className="who-is-fabian">My name is <strong>Fabian Kiprop</strong> and I am a product designer
                        and frontend engineer based in Nairobi, Kenya. I am currently designing digital experiences
                        at <a rel="noopener noreferrer" target="_blank" href="https://rigr.ai/">Rigr AI</a> and
                        building <a
                            target="_blank" rel="noopener noreferrer" href="https://jovinga.com/landing">Jovinga</a> in
                        my spare time.</p>

                    <div className="work">
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.dropbox.com/sh/ontz0spjmm1z68q/AACr-sRLLXKpSLLBN6MzonaVa?dl=0"
                            className="link -resume"><i className="far fa-user"></i> Resume</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://dribbble.com/Fabian3drop"
                            className="link -dribbble"><i
                                className="fab fa-dribbble"></i> Dribbble Portfolio</a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.dropbox.com/sh/nbemdypmo3f3lp7/AABsTQQhATjlVsgY2MHs0OqGa?dl=0"
                            className="link -showcase"><i
                                className="fas fa-photo-video"></i> Project Showcase</a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/fabian-k-65023b92/" className="link -linked-in"><i
                                className="fab fa-linkedin"></i> LinkedIn</a>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:fabrotich@gmail.com"
                            className="link -email"><i
                                className="far fa-envelope"></i> Email</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing;
